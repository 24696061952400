import type { ThemeUIStyleObject } from "@theme-ui/css"

const boxMain: ThemeUIStyleObject = {
  position: "fixed",
  top: "18%",
  left: "50%",
  padding: "16px 24px",
  borderRadius: "8px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 3px rgba(0, 0, 0, 0.1)",
  transition: "0.2s all",
  transform: "translate(-50%, 10%)",
  minWidth: "600px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "1rem",
  zIndex: 999999,

  a: {
    alignSelf: "center",
  },
}

const boxClose: ThemeUIStyleObject = {
  opacity: 0,
}

const boxOpen: ThemeUIStyleObject = {
  opacity: 1,
  transform: "translate(-50%, -50%)",
}

const colorDefault: ThemeUIStyleObject = {
  background: "rgba(0, 0, 0, 0.8)",
}

const colorSuccess: ThemeUIStyleObject = {
  background: "#ECFDF3",
}

const colorError: ThemeUIStyleObject = {
  background: "#FEF3F2",
}

const textMessage: ThemeUIStyleObject = {
  color: "tertiary500",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "21px",
}

const colorGrey: ThemeUIStyleObject = {
  color: "#818996",
}
const colorTertiary500: ThemeUIStyleObject = {
  color: "#344054",
  cursor: "pointer",
  fontWeight: "600",
  fontSize: "14px",
}

const AlertStyle = () => {
  return {
    boxMain,
    boxOpen,
    boxClose,
    colorDefault,
    colorSuccess,
    colorError,
    colorGrey,
    colorTertiary500,
    textMessage,
  }
}

export default AlertStyle
